<template>
  <!--期货应付项目 -->
  <div class="order-base__warp">
    <div class="erp-form-bar bar-sticky" style="top: 0">
      <div
        class="erp-primary__btn"
        @click="showDetail(2)"
        v-if="(detailsData.orderStatus === 1 || detailsData.orderStatus === 4) && !detailsData.apAudit && hasPermission('erp:fmArap:saveApAudit')"
      >
        录入应付
      </div>
      <div
        class="erp-primary__btn"
        @click="showDetail(3)"
        v-if="
          (detailsData.orderStatus === 1 || detailsData.orderStatus === 4) &&
          (detailsData.apAudit || detailsData.modifyApAudit) &&
          hasPermission('erp:fmAp:auditArap')
        "
      >
        审核
      </div>
      <div class="erp-default__btn" @click="back">返回</div>
    </div>
    <!-- 55等分布局 -->
    <el-row :gutter="0" type="flex" class="op-16">
      <el-col :span="12" style="margin-right: 80px">
        <div class="erp-form__title f16">查看应付项目</div>
        <el-row :gutter="30" type="flex" class="pl-12 flex-warp">
          <el-col :span="12">
            <erp-view label="销售合同号" :value="detailsData.businessContractNo"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="客户" :value="detailsData.customerName"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="件数" :value="detailsData.packageCountDesc"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="实际净重(kg)" :value="detailsData.actualWeightSumDisplay"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="供应商合同号" :value="detailsData.supplierContractNo"></erp-view>
          </el-col>
          <!--to-do-->
          <el-col :span="12">
            <erp-view label="提单号" :value="detailsData.ladingBillNo"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="柜号" :value="detailsData.containerNo"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="抬头" :value="detailsData.consigneeShortName"></erp-view>
          </el-col>
        </el-row>
      </el-col>
    </el-row>

    <!-- 应付项目（待审核） -->
    <payablesAudit
      :data="detailsData.apAudit"
      :baseData="detailsData"
      :orderArapId="orderArapId"
      :version="version"
      @cancel="auditCancel"
      :arTradeCurrencyDesc="detailsData.arTradeCurrencyDesc"
      v-if="detailsData.apAudit"
    ></payablesAudit>
    <!-- 修改应付项目（待审核） -->
    <receivablesEdit
      :baseData="detailsData"
      :data="detailsData.modifyApAudit"
      :orderArapId="orderArapId"
      :arTradeCurrencyDesc="detailsData.arTradeCurrencyDesc"
      :version="version"
      @cancel="auditCancel"
      v-if="detailsData.modifyApAudit"
    ></receivablesEdit>

    <!-- 应付项目（审核已通过） -->
    <payablesPass
      :baseData="detailsData"
      :data="detailsData.apConfirmVo"
      :orderArapId="orderArapId"
      :version="version"
      :arTradeCurrencyDesc="detailsData.arTradeCurrencyDesc"
      v-if="detailsData.apConfirmVo"
      @cancel="auditCancel"
    ></payablesPass>
    <!--审核页面-->
    <erp-drawer :visible="visible && type === 3" @close="closeDialog" erp class="no-padding fix-top-header" width="1310">
      <auditAccrued
        :isCope="2"
        :baseData="detailsData"
        :orderArapId="orderArapId"
        :version="version"
        :arTradeCurrencyDesc="detailsData.arTradeCurrencyDesc"
        @cancel="closeDialog"
      >
      </auditAccrued>
    </erp-drawer>
    <erp-drawer :visible="visible && type === 2" @close="closeDialog" erp class="no-padding fix-top-header">
      <payablesForm :baseData="detailsData" :orderArapId="orderArapId" :version="version" @cancel="closeDialog"></payablesForm>
    </erp-drawer>
  </div>
</template>
<script>
import payablesAudit from './components/payablesAudit.vue';
import payablesPass from './components/payablesPass.vue';
import receivablesEdit from './components/payablesPassEdit.vue';
import auditAccrued from './components/auditAccrued.vue';
import payablesForm from './components/payablesForm.vue';
import { getRouterParams } from '@/utils/publicMethods';

export default {
  name: 'detailCope',
  components: {
    payablesAudit,
    payablesPass,
    receivablesEdit,
    auditAccrued,
    payablesForm,
  },
  props: {},
  data() {
    return {
      detailsData: {},
      orderArapId: null, // 列表获取id
      version: null, // 列表获取版本
      type: null, // 切换不同显示弹窗
      visible: false, // 侧栏弹出显示
    };
  },
  methods: {
    auditCancel(status) {
      if (status) {
        this.getCollectionData(this.orderArapId);
      }
    },
    closeDialog(status) {
      this.visible = false;
      this.auditCancel(status);
    },
    getCollectionData(id) {
      // 获取列表信息
      this.ajax({
        type: 'POST',
        url: '/malicrm/orderArap/queryOrderArapDetailById',
        data: {
          orderArapId: id,
          arapType: 2,
        },
        success: (res) => {
          if (res.code === 200) {
            this.detailsData = res.result;
            this.version = res.result.version;
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    showDetail(type) {
      // 显示不同详情
      this.type = type;
      this.visible = true;
    },
    back() {
      // 返回
      this.$router.push('/erp/financial/accruedCope');
    },
  },
  created() {
    const { id, version } = getRouterParams('detailCope');
    this.orderArapId = id;
    this.version = version;
    this.getCollectionData(id);
  },
};
</script>

<style lang="scss" scoped></style>
